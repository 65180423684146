import React from "react";
import { useTranslation } from "react-i18next";

export const Architecture = (props) => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
              <img style={{"marginTop":"90px"}} src={t("Architecture.archpic")} className="img-responsive" alt="" draggable="false" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2><span style={{color: "rgba(107, 199, 183, 1)"}}>{t("Architecture.archTitleLine1")}</span><br/>{t("Architecture.archTitleLine2")}</h2>
              <p>{props.data ? t("Architecture.paragraph1") : "loading..."}</p>
              <p>{props.data ? t("Architecture.paragraph2") : "loading..."}</p>
              <p>{props.data ? t("Architecture.paragraph3") : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
