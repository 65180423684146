import React from "react";
import { useTranslation } from "react-i18next";

export const ClientsProposition = (props) => {
  const { t } = useTranslation();

  return (
    <div id="clientsproposition" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("ClientsProposition.Title1")}<br></br>{t("ClientsProposition.Title2")}</h2>        </div>
        <div className="gridRow">

        {props.data
          ? t("ClientsProposition.clientsPropositionDetails", { returnObjects: true }).map((d, i) => (
            <div className="col-xs-12 col-md-4" style={{display:"flex-column"}}> 
              <div key={`${d.name}-${i}`} 
              style={{
                borderColor:"rgba(107, 199, 183, 1)",
                borderStyle:"solid",
                borderWidth:"3px",
                borderRadius:"10px",
                padding:"20px 30px 20px 30px",
                margin:"5px"
                // height: "100%"
                // height:"100px"
                }}>
                {" "} 
                <h3><b>{d.name}</b></h3>
                <ul>
                    {d.paragraphs?.map((dd, ii) => (
                          <li 
                          key={`${dd}-${ii}`} 
                          style={{
                            margin: "20px",
                            textAlign: "left",
                            }}>
                          <span className="fa fa-check"/> {dd} <br/></li>
                        ))
                    }
                </ul>
                {/* <p>{props.data ? d.paragraphs[0] : "loading..."}</p> */}
                {/* <p>{props.data ? t("Architecture.paragraph2") : "loading..."}</p> */}
              </div>
            </div>
            ))
          : "loading"
          }

          {/* <div className="col-xs-12 col-md-4">
            <div style={{
              borderColor:"rgba(107, 199, 183, 1)",
              borderStyle:"solid",
              borderWidth:"5px",
              borderRadius:"10px",
              padding:"50px"
              }}>

              <h2>{props.data ? t("ClientsProposition.name1") : "loading..."}</h2>
              <p>{props.data ? t("Architecture.paragraph1") : "loading..."}</p>
              <p>{props.data ? t("Architecture.paragraph2") : "loading..."}</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="about-text">
              <h2>{t("Architecture.archTitleLine1")}<br/>{t("Architecture.archTitleLine2")}<br/><span style={{color: "rgba(107, 199, 183, 1)"}}>{t("Architecture.archTitleLine3")}</span></h2>
              <p>{props.data ? t("Architecture.paragraph1") : "loading..."}</p>
              <p>{props.data ? t("Architecture.paragraph2") : "loading..."}</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="about-text">
              <h2>{t("Architecture.archTitleLine1")}<br/>{t("Architecture.archTitleLine2")}<br/><span style={{color: "rgba(107, 199, 183, 1)"}}>{t("Architecture.archTitleLine3")}</span></h2>
              <p>{props.data ? t("Architecture.paragraph1") : "loading..."}</p>
              <p>{props.data ? t("Architecture.paragraph2") : "loading..."}</p>
            </div>
          </div> */}

          {/* {props.data
            ? t("Services.servicesDetails", { returnObjects: true }).map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};


// {props.data
//   ? t("ClientsProposition.clientsPropositionDetails", { returnObjects: true }).map((d, i) => (
    
//     <div className="col-xs-12 col-md-4"> 
//       <div key={`${d.name}-${i}`} 
//       style={{
//         borderColor:"rgba(107, 199, 183, 1)",
//         borderStyle:"solid",
//         borderWidth:"5px",
//         borderRadius:"10px",
//         padding:"50px"
//         }}>
//         {/* {" "}  */}
//         <h3>{d.name}</h3>
//         <p>{props.data ? d.paragraphs[0] : "loading..."}</p>
//         {/* <p>{props.data ? t("Architecture.paragraph2") : "loading..."}</p> */}
//       </div>
//     </div>
//     ))
//   : "loading"
//   }
