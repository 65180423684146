import React from "react";
import { useTranslation } from "react-i18next";

export const Conferences = (props) => {
  const { t } = useTranslation();
  return (
    <div id="conferences">
      <div className="container">
        <div className="section-title text-center">
          <h2> <span style={{color: "rgba(107, 199, 183, 1)"}}>Hadal</span> { t("Conferences.conferencesTitle") }</h2>
        </div>
        <div className="row" style={
            {
              display:"flex",
              height: "20vw",
            }
          }>
          <iframe title="nexthop_youtube" src="https://www.youtube.com/embed/FdXXlYLRtTE?si=1iAMRA0ggC-EUyHh"
          style={
            {
              position:"relative",
              height: "20vw",
              width: "70%",
              margin: "auto",
            }
          }
          >
          </iframe>
        </div>
        <div className="row" style={
            {
              marginTop: "5vh",
              display:"flex",
              height: "20vw",
            }
          }>
          <iframe title="linkmeetup_youtube" src="https://www.youtube.com/embed/jzLh-m30cJo"
          style={
            {
              position:"relative",
              height: "20vw",
              width: "70%",
              margin: "auto",
            }
          }
          >
          </iframe>
        </div>
      </div>
    </div>
  );
};
