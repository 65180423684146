import React, { useState } from "react";
import "./styles.css";
import i18next from "../../../services/i18n";
import LanguageIcon from '@mui/icons-material/Language';
import {
  Box,
  Paper,
  Button,
  Stack,
  Typography,
  TextField,
} from '@mui/material';

const langs = { 
  ru: {text: 'Eng', code: 'ru'},
  en: {text: 'Рус', code: 'en'}
}

export function LangSelector() {
  const [language, setLanguage] = useState(langs.ru);
  return (
    <div style={{display: "flex"}}>
      <Button
      sx={{height: "36px"}}
      onClick={
        () => {
          const newLang = language.code != langs.ru.code ? langs.ru : langs.en;
          i18next.changeLanguage(newLang.code);
          setLanguage(newLang);
        }
      }><LanguageIcon ></LanguageIcon>{language.text}</Button>
    </div>
  );
}

