import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import JsonDataRu from "../data/data_ru.json";
import JsonDataEn from "../data/data_en.json";

// "Inline" English and Arabic translations.

// We can localize to any language and any number of languages.

const resources = {

  en: {

    translation: {

        ...JsonDataEn,

    },

  },

  ru: {

    translation: {

        ...JsonDataRu,

    },

  },

};


i18next

  .use(initReactI18next)

  .init({

    resources,

    lng: "ru",

    interpolation: {

      escapeValue: false,

    },

  });

export default i18next;