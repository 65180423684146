import React from "react";
import { useTranslation } from "react-i18next";
import { LangSelector } from "./system/LangSwitch/LangSwitch";

export const Navigation = (props) => {
  const { t } = useTranslation();

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
          <span style={{color: "rgba(107, 199, 183, 1)"}}>Hadal</span> Project
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
            <li>
              <a href="#about" className="page-scroll">
                {t("Navigation.about")}
              </a>
            </li>
            {/* <li>
              <a href="#news" className="page-scroll">
                {t("Navigation.news")}
              </a>
            </li> */}
            <li>
              <a href="#services" className="page-scroll">
                {t("Navigation.solution")}
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                {t("Navigation.gallery")}
              </a>
            </li> */}
            <li>
              <a href="#team" className="page-scroll">
                {t("Navigation.team")}
              </a>
            </li>
            <li>
              <a href="#conferences" className="page-scroll">
                {t("Navigation.conferences")}
              </a>
            </li>
            <li>
              <a href="#teaching" className="page-scroll">
                {t("Navigation.teaching")}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                {t("Navigation.contact")}
              </a>
            </li>
            <li>
              <LangSelector></LangSelector>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
