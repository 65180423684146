import React from "react";
import { useTranslation } from "react-i18next";


export const Teaching = (props) => {
  const { t } = useTranslation();
 
  return (
    <div id="teaching" className="text-center" alt="" >
      <div className="container" style={{marginTop: "100px"}}>
         <div className="row">
          <div className="col-xs-12 col-md-6" style={{marginBottom: "50px"}}>
              {" "}
              <div style={{"display": "flex", "max-width": "277", "align-items": "center", "margin": "auto", "margin-bottom": 30}}>
                <img src={t("Teaching.hadalLogo")}  alt="" style={{"width": "45%"}}/>{" "}
                <div style={{"width": "45%", "margin-top": "12%", "height": "100%"}}><p style={{"text-align": "center","margin-bottom": "auto", "color": "white", "transform": `rotate(45deg)`, "font-weight": "900"}}>+</p></div>
                <img src={t("Teaching.miptLogo")}  alt="" style={{"width": "45%"}}/>{" "}
              </div>
              {" "}
              <img src="img/teaching.jpeg" className="img-left" alt=""/>{" "}
          </div>
          <div className="col-xs-12 col-md-6">
          <div className="teaching-text">
        <h2> {t("Teaching.title")} </h2>
        {
          t("Teaching.info", { returnObjects: true }).map((d, i) => (
            <p>{d}</p>
          ))
        }
       <h3>{t("Teaching.studies")}:</h3>
         <div className="list">
           <div className="col-lg-6 col-sm-6 col-xs-12">
               <ul>
              {props.data
            ? t("Teaching.Why", { returnObjects: true }).map((d, i) => (
         <li key={`${d}-${i}`}><span className="fa fa-check"/> {d}</li>
         ))
       : "loading"}
       </ul>
    </div>
  <div className="col-lg-6 col-sm-6 col-xs-12">
    <ul>
      {props.data
        ? t("Teaching.Why2", { returnObjects: true }).map((d, i) => (
           <>
            <li key={`${d}-${i}`}><span className="fa fa-check"/> {d}</li>
              </>
                ))
                  : "loading"}
                 </ul>
             </div>
           </div>
        </div>  
          <a href="https://courses.mipt.ru/course/view/89" className="teaching-btn">
             <button className="btn">{t("Teaching.btn")}</button>   
          </a>
           </div>
         </div>
      </div>
   </div>
                              
         
  );
};