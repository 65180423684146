import React from "react";
import { useTranslation } from "react-i18next";

export const About = (props) => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.png" draggable="false" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2> { t("About.aboutTitle") } </h2>
              <p>{props.data ? t("About.paragraph1") : "loading..."}</p>
              <p>{props.data ? t("About.paragraph2") : "loading..."}</p>
              <h3>{ t("About.keyFeatures") }:</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? t("About.Why", { returnObjects: true }).map((d, i) => (
                          <li key={`${d}-${i}`}><span className="fa fa-check"/> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
