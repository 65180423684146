import React from "react";
import { useTranslation } from "react-i18next";

export const Team = (props) => {
  const { t } = useTranslation();

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Team.teamTitle")}</h2>
        </div>
        <div className="row">
            <div className="card">
           
                <div className="circle">
                    <img src="img/teamDL.PNG" draggable="false"/>
                   
                    
                    
                     <h4>{t("Team.DL_name")}</h4> 
                   
                    
                    <h5> {t("Team.DL_rang")}</h5> 
                    </div>
                    
                <p className="title">{t("Team.DL_descr")}</p>
               
                </div>
           
            <div className="card">
                <div className="circle">
                    <img src="img/teamKC.JPG" draggable="false"/>
                    <h4>{t("Team.KC_name")}</h4>
                    <h5> {t("Team.KC_rang")}</h5>
                </div>
                
                     
                <p className="title">{t("Team.KC_descr")}</p>
                
            </div>
            <div className="card">
                <div className="circle">
                    <img src="img/teamVL.PNG" draggable="false"/>
                    <h4>{t("Team.VL_name")}</h4>
                    <h5> {t("Team.VL_rang")} </h5>
                </div>
                
                <p className="title">{t("Team.VL_descr")}</p>
                

            </div>
            <div className="card">
                <div className="circle">
                    <img src="img/teamEZ.JPG" draggable="false"/>
                    <h4 >{t("Team.EZ_name")}</h4>
                    <h5> {t("Team.EZ_rang")} </h5>
                </div>
                
                <p className="title">{t("Team.EZ_descr")}</p>
                
            </div>
        </div>
        <div className="section-title" style={{marginBottom: "0px"}}>
          <h2>{t("Team.ourMissionTitle")}</h2>
        </div>
        <div className="col-xs-12 col-md-12" style={{display: "block", marginLeft: "auto", marginRight: "auto"}}>
              <p>{props.data ? t("Team.ourMissionBody") : "loading..."}</p>
          </div>
      </div>
    </div>
  );
};
